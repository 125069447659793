<template>
  <VContainer fill-height>
    <VRow>
      <VCol cols="1" />
      <VCol cols="10" />
      <VCol cols="1" />
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'Grid1x10x1',
};
</script>

<style scoped lang="scss">
.container {
  background: map-get($tt-light-red, 'pale');
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px solid map-get($tt-light-mono-100, 'base');
  }

  &::before {
    content: 'container';
    position: absolute;
    left: 0;
    top: 0;
    text-align: left;
    z-index: 3;
    background: map-get($tt-light-red, 'pale');
    border-bottom: 1px solid map-get($tt-light-mono-100, 'base');
    padding: 12px;
  }
}

.row {
  background: map-get($tt-light-mono-0, 'base');
  border-top: 1px dotted map-get($tt-light-mono-100, 'base');
  border-bottom: 1px dotted map-get($tt-light-mono-100, 'base');
  height: 100%;
  position: relative;

  &::before {
    content: 'row';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    text-align: left;
    z-index: 3;
    background: map-get($tt-light-mono-0, 'base');
    border-top: 1px dotted map-get($tt-light-mono-100, 'base');
    border-bottom: 1px dotted map-get($tt-light-mono-100, 'base');
    padding: 12px;
  }
}

.col {
  background: map-get($tt-light-mono-0, 'base');
  border-right: 1px dashed map-get($tt-light-mono-100, 'base');
  height: 100%;
  position: relative;

  &:first-child {
    border-left: 1px dashed map-get($tt-light-mono-100, 'base');
  }

  &::before {
    content: 'col';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    z-index: 3;
    background: map-get($tt-light-blue, 'pale');
    border-left: 1px dashed map-get($tt-light-mono-100, 'base');
    border-right: 1px dashed map-get($tt-light-mono-100, 'base');
    padding: 12px;
  }

  &::after {
    content: '';
    display: block;
    background: map-get($tt-light-blue, 'pale');
    height: 100%;
    left: 0;
    top: 0;
    text-align: center;
    width: 100%;
  }
}
</style>
